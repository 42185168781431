const config = {
  STRIPE_KEY: "pk_test_51I4Y0tG8Eo6V1pnSKebHHY7QtWwJQhViu95OJNNZLwgorZ58nXSNKvIlKJhHCTssg7FX6OBpbk3e1MCSCLOlJKiD00hfuPQdbs",
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "us-east-1",
    BUCKET: "mgeiser-notes-app-upload",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://bn06molqv0.execute-api.us-east-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_bCMx6dZ1R",
    APP_CLIENT_ID: "4eht3gsj1s6ms7q85e73s9qn1p",
    IDENTITY_POOL_ID: "us-east-1:0a46ebaf-c220-4432-b67b-1938f05da7b7",
  },
};

export default config;